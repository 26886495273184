import React, { useContext } from "react";
import {
	App as BundleJsApp,
	Controller as BundleJSController,
} from "@bundlejs/react-router";

import { useLocation, matchPath } from "react-router-dom";

import { StaticContext } from "../contexts";
import { RootStoreContext } from "@bundlejs/mobx";
import useLoading from "../hooks/useLoading";
import { observer } from "mobx-react";

const AppController = observer(({ routes }) => {
	const location = useLocation();
	const rootStore = useContext(RootStoreContext);
	const initialStaticContext = useContext(StaticContext);

	const route = routes.find((route) => matchPath(location.pathname, route));
	if (!route) return;

	const [LoadingTemplate, onLoading, loadingStore] = useLoading({
		show: !route.ssr,
		_id: `${route.path}`,
	});

	const onStaticContextChangeHandler = (staticContext, route) => {
		rootStore.setStores({
			stores: route.stores,
			libs: route.libs,
			staticContext,
			force: true,
		});
	};

	if (route.ssr) {
		onStaticContextChangeHandler(initialStaticContext, route);
	}

	return (
		<BundleJSController
			routes={routes}
			route={route}
			onLoading={onLoading}
			StaticContext={StaticContext}
			onStaticContextChange={onStaticContextChangeHandler}
		>
			<RootStoreContext.Provider value={rootStore}>
				{route.load ? (
					<LoadingTemplate>
						<BundleJsApp routes={routes} StaticContext={StaticContext} />
					</LoadingTemplate>
				) : (
					!loadingStore.show && (
						<BundleJsApp routes={routes} StaticContext={StaticContext} />
					)
				)}
			</RootStoreContext.Provider>
		</BundleJSController>
	);
});

export default AppController;
