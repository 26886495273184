import { HttpLink } from "apollo-link-http";
import { isBrowser, getCookieByName } from "@utils";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { BatchHttpLink } from "apollo-link-batch-http";

const { GRAPHQL_HOST } = process.env;

export const httpLink = (fetch, options = {}) => {
	let uri = GRAPHQL_HOST;
	return new HttpLink({
		uri,
		fetch,
		...options,
	});
};

export const batchHttpLink = (fetch, options = {}) => {
	let uri = GRAPHQL_HOST;
	return new BatchHttpLink({
		uri,
		fetch,
		...options,
	});
};

export const authLink = () =>
	setContext((_, context) => {
		if (!isBrowser) return context;

		const token = getCookieByName("token");
		const _csrf = getCookieByName("_csrfToken");
		const { headers } = context;
		return {
			headers: Object.assign(
				{
					...headers,
					"csrf-token": _csrf,
				},
				token ? { authorization: token } : {}
			),
		};
	});

export const errorLink = () =>
	onError(({ graphQLErrors, networkError, operation }) => {
		console.log({ graphQLErrors, networkError, operation });
		if (graphQLErrors) {
			graphQLErrors.map(({ message, locations, path, extensions }) =>
				console.error(
					`[GraphQL error]: Message: ${JSON.stringify(
						message,
						null,
						2
					)}, \nLocation: ${JSON.stringify(
						locations,
						null,
						2
					)}, \nPath: ${JSON.stringify(path, null, 2)}, \n${
						extensions ? JSON.stringify(extensions, null, 2) : ""
					}
					`
				)
			);
		}
		if (operation) console.error(`[Operation]: ${operation.operationName}`);
		if (networkError) {
			const { name, statusCode, result } = networkError;
			if (graphQLErrors)
				console.error(
					`[Network error]: ${JSON.stringify({ name, statusCode }, null, 2)}`
				);
			else
				console.error(
					`[Network error]: ${JSON.stringify(
						{ name, statusCode, result },
						null,
						2
					)}`
				);
		}
		if (operation)
			console.warn(
				`[Payload]: ${JSON.stringify(operation.variables, null, 2)}`
			);
	});
