import { observable } from "mobx";

class AccountStore {
	@observable staticContext;

	constructor(rootStore, staticContext) {
		this.rootStore = rootStore;
		this.staticContext = staticContext;
	}

	get isAuthenticated() {
		const { account } = this.staticContext;
		return account && account._id;
	}

	get accountType() {
		const { account } = this.staticContext;
		return account && account.type;
	}

	get isUser() {
		if (!this.isAuthenticated) return false;
		return this.accountType === "USER";
	}

	get isAdmin() {
		if (!this.isAuthenticated) return false;
		return this.accountType === "ADMIN";
	}

	async create({ email, password, phone } = {}) {
		if (!email || !password || !phone)
			throw new Error("Invalid email, password, or phone");
		try {
			const { default: axios } = await import("axios");
			await axios.post("/account/create", {
				email,
				password,
				phone,
			});
			return await this.login({ email, password });
		} catch (err) {
			throw err;
		}
	}

	async login({ email, password } = {}, redirect = false) {
		if (!email || !password) throw new Error("Invalid email or password");
		try {
			const { default: axios } = await import("axios");
			const response = await axios.post("/auth/login", {
				email,
				password,
			});
			const account = response.data?.account;
			this.staticContext.account = account;

			if (redirect) {
				if (this.isUser) window.location.href = "/account/bookings";
			}

			return account;
		} catch (err) {
			if (err.response?.data?.message)
				throw new Error(err.response.data.message);
			throw err;
		}
	}

	async logout() {
		try {
			const { default: axios } = await import("axios");
			await axios.post("/auth/logout");
			window.location.href = "/";
		} catch (err) {
			if (err.response?.data?.message)
				throw new Error(err.response.data.message);
			throw err;
		}
	}
}

export default AccountStore;
