import React from "react";

import withStyles from "./withStyles";
import withConstants from "./withConstants";
import withStores from "./withStores";

export default (route, components) => {
	return withStores(route.extensions?.stores || {})(
		withConstants(route.constants)(
			withStyles(
				Object.assign({}, route.extensions?.styles || {}, route.styles || {})
			)(components.default)
		)
	);
};
