import { batchHttpLink, authLink, errorLink } from "./link";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { from } from "apollo-link";

export default ({ fetch, ssr = false, window, options = {} }) => {
	const defaultOptions = {
		watchQuery: {
			fetchPolicy: "no-cache",
			errorPolicy: "ignore",
		},
		query: {
			fetchPolicy: "no-cache",
			errorPolicy: "all",
		},
		mutate: {
			errorPolicy: "all",
			fetchPolicy: "no-cache",
		},
	};

	return new ApolloClient({
		link: from([errorLink(), authLink(), batchHttpLink(fetch, options)]),
		cache: new InMemoryCache({
			addTypename: true,
		}).restore(window ? window.__APOLLO_STATE__ : undefined),
		ssrMode: ssr,
		defaultOptions,
	});
};
