import React, { useContext } from "react";
import { constantsContext } from "../contexts";

export default (constants) => (Component) => (props) => {
	const currentConstants = useContext(constantsContext) || {};
	Object.assign(currentConstants, constants);

	return (
		<constantsContext.Provider value={currentConstants}>
			<Component {...currentConstants} {...props} />
		</constantsContext.Provider>
	);
};
