import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const theme = createTheme({
	spacing: 4,
	palette: {
		primary: {
			main: "#0e6be6",
		},
		secondary: {
			main: "#222222",
		},
		error: {
			main: red.A700,
		},
		background: {
			default: "#fff",
		},
		success: {
			main: "#2ac389",
		},
	},
	overrides: {
		MuiRating: {
			iconFilled: {
				color: "#ff6474",
			},
			iconHover: {
				color: "#ff6474",
			},
		},
		MuiSelect: {
			select: {
				"&:focus": {
					backgroundColor: "#FFF",
				},
			},
			outlined: {
				paddingRight: "42px !important",
			},
		},
		MuiButton: {
			root: {
				borderRadius: 8,
			},
			contained: {
				backgroundColor: "#f0f0f0",
			},
			containedSizeLarge: {
				fontSize: "1rem",
				padding: "10px 22px",
			},
			textSizeLarge: {
				fontSize: "1rem",
			},
			outlinedSizeLarge: {
				fontSize: "1rem",
				padding: "9px 21px",
			},
		},
		MuiPaper: {
			root: {
				color: "inherit",
			},
			rounded: {
				borderRadius: 8,
			},
		},
		MuiLink: {
			root: {
				cursor: "pointer",
			},
		},
		MuiInputBase: {
			input: {
				height: "inherit",
				padding: "7px 0 7px",
				color: "#222222",
				fontWeight: 600,
				backgroundColor: "#FFFFFF",
				borderRadius: 8,
			},
			inputMultiline: {
				lineHeight: 1.5,
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: 8,
			},
			notchedOutline: {
				borderColor: "#dadada",
			},
		},
		MuiAlert: {
			root: {
				borderRadius: 8,
			},
			filledError: {
				backgroundColor: "#ff5459",
			},
			standardError: {
				color: red.A700,
			},
		},
		MuiAvatar: {
			root: {
				backgroundColor: "rgb(224, 224, 224)",
			},
			colorDefault: {
				color: "#222",
				backgroundColor: "#e8f4fd",
				fontWeight: 700,
			},
			rounded: {
				borderRadius: 8,
			},
		},
		MuiTooltip: {
			tooltip: {
				backgroundColor: "rgb(34 34 34)",
				borderRadius: 8,
				fontSize: 12,
				lineHeight: 1.6,
				maxWidth: 260,
				padding: "8px 14px",
				boxShadow:
					"0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
			},
			arrow: {
				color: "rgb(34 34 34)",
			},
		},
		MuiSnackbar: {
			root: { zIndex: 1299 },
		},
		MuiBadge: {
			root: {
				fontWeight: 600,
			},
			colorError: {
				backgroundColor: "#ff5459",
			},
		},
		MuiChip: {
			root: {
				backgroundColor: "#f0f0f0",
				color: "rgb(0 0 0 / 87%)",
			},
			label: {
				fontWeight: 600,
				fontSize: 12,
			},
		},
		MuiCard: {
			root: { height: "100%" },
		},
		MuiCardActionArea: {
			root: { height: "100%" },
		},
		MuiCardContent: {
			root: { height: "100%" },
		},
		MuiBackdrop: {
			root: {
				backgroundColor: "rgba(0, 0, 0, 0.7)",
			},
		},
		MuiSvgIcon: {
			colorError: {
				color: "#ff5459",
			},
		},
		MuiTableContainer: {
			root: { borderTop: "3px solid #005fb9" },
		},
		MuiTable: {
			root: {
				"& caption": {
					captionSide: "top",
					fontWeight: 600,
					padding: 16,
					fontSize: 14,
					borderBottom: "1px solid rgba(224, 224, 224, 1)",
					color: "#222",
					textTransform: "uppercase",
				},
			},
		},
		MuiTableRow: {
			root: {
				"&:nth-of-type(odd)": {
					backgroundColor: "#fafafa",
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: 16,
			},
			head: {
				fontWeight: 600,
				fontSize: 16,
				color: "#005fb9",
				borderBottom: "2px solid rgba(224, 224, 224, 1)",
				backgroundColor: "#FFF",
			},
		},
	},
	typography: {
		fontFamily: [
			"Circular",
			"SharpSans",
			"Helvetica Neue",
			"Arial",
			"sans-serif",
		].join(","),
		button: {
			textTransform: "inherit",
			fontWeight: "600",
			whiteSpace: "nowrap",
		},
	},
});

export default theme;
