import React, { useContext } from "react";
import { RootStoreContext, StaticContext } from "../contexts";

export default (stores = {}, options = {}) => {
	return (Component) => {
		return (props) => {
			const rootStore = useContext(RootStoreContext);
			const ctx = useContext(StaticContext);
			rootStore.addStores(
				Object.assign(options, { stores, staticContext: ctx })
			);
			return (
				<RootStoreContext.Provider value={rootStore}>
					<Component {...props} />
				</RootStoreContext.Provider>
			);
		};
	};
};
