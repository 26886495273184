
			export default {
				
accountByToken: () => import("./queries/accountByToken.json"),
accounts: () => import("./queries/accounts.json"),
booking: () => import("./queries/booking.json"),
bookingByToken: () => import("./queries/bookingByToken.json"),
bookings: () => import("./queries/bookings.json"),
bookingsByToken: () => import("./queries/bookingsByToken.json"),
cancelBooking: () => import("./mutations/cancelBooking.json"),
changePassword: () => import("./mutations/changePassword.json"),
createAccount: () => import("./mutations/createAccount.json"),
createAppointment: () => import("./mutations/createAppointment.json"),
createBooking: () => import("./mutations/createBooking.json"),
createBookingByToken: () => import("./mutations/createBookingByToken.json"),
createTransaction: () => import("./mutations/createTransaction.json"),
generatePrice: () => import("./queries/generatePrice.json"),
getPrices: () => import("./queries/getPrices.json"),
getSetupIntentClientSecret: () => import("./queries/getSetupIntentClientSecret.json"),
login: () => import("./queries/login.json"),
updateAccountByToken: () => import("./mutations/updateAccountByToken.json"),
updateAppointment: () => import("./mutations/updateAppointment.json"),
updateAppointments: () => import("./mutations/updateAppointments.json"),
updateBooking: () => import("./mutations/updateBooking.json"),
updateBookingByToken: () => import("./mutations/updateBookingByToken.json"),
updateDefaultPaymentMethod: () => import("./mutations/updateDefaultPaymentMethod.json"),
verifyAuthenticatedAccount: () => import("./queries/verifyAuthenticatedAccount.json")
			}
		