import React, { useContext } from "react";
import { RootStore } from "@bundlejs/mobx";
import { ScrollToTop } from "@bundlejs/react-router";
import { useHistory } from "react-router-dom";
import { disableFragmentWarnings } from "graphql-tag";

import { StaticContext } from "../contexts";
import { RootStoreContext } from "@bundlejs/mobx";
import AppController from "./AppController.jsx";

import { isBrowser } from "@utils";

import {
	registerApolloClient,
	registerMarkup,
	registerApolloBatchClient,
} from "@bundlejs/markup";

import apolloClient from "../libs/apollo/client.js";
import apolloBatchClient from "../libs/apollo/batch.js";
import markup from "../markup";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../libs/theme";

import * as globalStores from "@stores/index";

import "../assets/scss/material-kit-pro-react.scss?v=1.9.0";

disableFragmentWarnings();

if (isBrowser) {
	registerApolloClient(
		apolloClient({ ssr: false, options: { credentials: "include" } })
	);
	registerApolloBatchClient(
		apolloBatchClient({ ssr: false, options: { credentials: "include" } })
	);
	registerMarkup(markup);
}

const App = ({ routes = [], staticContext }) => {
	const history = useHistory();

	staticContext = staticContext || useContext(StaticContext) || {};
	const rootStore = new RootStore({
		staticContext,
		stores: globalStores,
		history,
	});

	return (
		<ThemeProvider theme={theme}>
			<RootStoreContext.Provider value={rootStore}>
				<StaticContext.Provider value={staticContext}>
					<AppController routes={routes} />
				</StaticContext.Provider>
			</RootStoreContext.Provider>
		</ThemeProvider>
	);
};

export default App;
